<script>
import './Subscriptions.styl'
import Subscription from '@/components/Subscription/Subscription.vue';
import {mapGetters} from 'vuex';
import {subscriptionTypeConstants} from '@/js/constants/subscriptionTypeConstants.js';

export default {
  name: 'subscriptions',
  components: {Subscription},
  computed: {
    ...mapGetters([
      'getActiveUserSubscriptions',
      'getInactiveUserSubscriptions',
    ]),
  },
  data () {
    return {
      openWithEdit: false,
      notificationTextFromPage: '',
    }
  },
  methods: {
    handleShowNotification (payload) {
      this.notificationTextFromPage = payload.notificationTextFromPage
      this.openWithEdit = payload.openWithEdit
    },
    getVisibleActive () {
      return this.getActiveUserSubscriptions.length > 0
    },
    getInactiveSubscriptions () {
      const types = [subscriptionTypeConstants.flyjetsPlus.code, subscriptionTypeConstants.flyjetsCalendar.code];

      return types
        .filter(type => !this.getActiveUserSubscriptions.some(s => s.plan.type.code === type))
        .map(type => this.getInactiveUserSubscriptions
          .filter(s => s.plan.type.code === type)
          .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
          .shift(),
        )
        .filter(subscription => subscription !== undefined);
    },
    getVisibleInactive () {
      return this.getInactiveSubscriptions().length > 0
    },
    getSubscription () {
      window.location.href = `${process.env.VUE_APP_NEW_LANDING}/?showSubscriptionPopup=true`
    },
  },
}
</script>

<template>
  <div class="scrollable">
    <h1>SUBSCRIPTIONS</h1>
    <div v-if="getVisibleActive()">
      <h2>ACTIVE</h2>
      <Subscription
        v-for="subscription in getActiveUserSubscriptions"
        :key="subscription.id"
        :subscription="subscription"
        :open-with-edit="openWithEdit"
        :notificationTextFromPage="notificationTextFromPage"
        @showNotification="handleShowNotification"/>
    </div>
    <div v-else-if="getVisibleInactive()">
      <h2>INACTIVE</h2>
      <Subscription
        v-for="subscription in getInactiveSubscriptions()"
        :key="subscription.id"
        :subscription="subscription"
        :open-with-edit="openWithEdit"
        :notificationTextFromPage="notificationTextFromPage"
        @showNotification="handleShowNotification"/>
    </div>
    <button v-else class="create-subscription-button" @click="getSubscription">
      Get subscription
    </button>
  </div>
</template>
